import { REGISTERED_APPS } from '../shared/types/Apps';
import { productsCoveredByDiscount } from '../shared/api/candyrack';
import { getCart } from '../shared/api/shared-app-requests';

import storage from '../shared/product-page-placement/storage';
import {
  loadCartAndCleanupAssociatedProductData,
  showInfoInCart,
} from '../shared/utils/cart';
import {
  CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
  CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY,
  CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY,
} from '../shared/constants/app';
import { logError, logInfo } from '../shared/utils/logging';
import throttle from '../shared/utils/throttle';

const FORMS =
  "form[action*='/checkout'], form[action*='/cart'], form[action*='/a/checkout']";

const MAXIMALY_ALLOWED_DISCOUNTS_BY_SHOPIFY = 5;

// used in CR popup and ProductPagePlacement
export async function saveDiscountCodeForCandyrack(
  code: string,
  variantId: number,
  discountedPrice: number | null,
  discount: string,
  relatedProductId: number,
) {
  logInfo(
    `Saving discount code, discounted price ${discountedPrice}, discount: ${discount}`,
    REGISTERED_APPS.CANDYRACK,
  );

  const currentDiscounts =
    storage.getItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY) || '';

  const discountsCount = currentDiscounts.split(',').length;
  // Adding only unique discounts
  if (
    !currentDiscounts.includes(code) ||
    discountsCount > MAXIMALY_ALLOWED_DISCOUNTS_BY_SHOPIFY
  ) {
    const discountCodes =
      currentDiscounts.trim().length === 0
        ? code
        : `${currentDiscounts},${code}`;
    storage.setItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY, discountCodes);

    updateDiscounts(REGISTERED_APPS.CANDYRACK);
  }
  const currentDiscountedProducts = getDiscountedProductWithDiscount();
  storage.setItem(
    CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY,
    JSON.stringify([
      ...currentDiscountedProducts,
      {
        variantId,
        discountedPrice,
        code,
        discount,
        relatedProductId,
      },
    ]),
  );
}

// Currently used in the checkout button
export function saveDiscountForCandyRackCheckoutButton(
  discount: string,
  variantId?: number,
) {
  const currentDiscounts =
    storage.getItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY) || '';
  logInfo(
    `Current discounts in storage: ${currentDiscounts}`,
    REGISTERED_APPS.CANDYCART,
  );
  const discountsCount = currentDiscounts.split(',').length;
  if (discountsCount > MAXIMALY_ALLOWED_DISCOUNTS_BY_SHOPIFY) {
    logInfo(
      `Too many discounts: ${discountsCount}. Returning.`,
      REGISTERED_APPS.CANDYCART,
    );
    return;
  }
  // Adding only unique discounts
  if (!currentDiscounts.includes(discount)) {
    const discountCodes =
      currentDiscounts.trim().length === 0
        ? discount
        : `${currentDiscounts},${discount}`;
    logInfo(
      `Discounts saved in storage after addition: ${discountCodes}`,
      REGISTERED_APPS.CANDYCART,
    );
    storage.setItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY, discountCodes);
  }
  const currentProducts = JSON.parse(
    storage.getItem(
      CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
    ) || '[]',
  );
  storage.setItem(
    CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
    JSON.stringify([
      ...currentProducts,
      {
        variantId,
        discount,
      },
    ]),
  );
}

/*
 * TODO: removeCandyRackCheckoutButtonDiscount, removeCandyRackAddToCartDiscount are amost identical.
 * Please refactor to avoid duplication next time we have something in that corner (so that some QA gets done).
 */
export function removeCandyRackCheckoutButtonDiscount(
  discount: string,
  variantId?: number,
) {
  const currentDiscounts =
    storage.getItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY) || '';
  logInfo(`Current discounts: ${currentDiscounts}`, REGISTERED_APPS.CANDYCART);

  const discountedProducts = JSON.parse(
    storage.getItem(
      CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
    ) || '[]',
  );

  const updatedProducts = discountedProducts.filter(
    (product: any) => product.variantId !== variantId,
  );

  const isDiscountNotUsedByOtherProducts = !updatedProducts.find(
    (product: any) => product.code === discount,
  );
  if (isDiscountNotUsedByOtherProducts) {
    const updatedDiscounts = currentDiscounts
      .split(',')
      .filter((singleDiscount) => singleDiscount !== discount)
      .join(',');
    storage.setItem(
      CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY,
      updatedDiscounts,
    );
    logInfo(
      `Discount ${discount} not used by other products, removed. Updated discounts: ${updatedDiscounts}`,
      REGISTERED_APPS.CANDYCART,
    );
  }
  storage.setItem(
    CANDYRACK_CHECKOUT_BUTTON_DISCOUNTED_PRODUCT_LOCAL_STORAGE_KEY,
    JSON.stringify(updatedProducts),
  );
}

/*
 * TODO: removeCandyRackCheckoutButtonDiscount, removeCandyRackAddToCartDiscount are amost identical.
 * Please refactor to avoid duplication next time we have something in that corner (so that some QA gets done).
 */
export function removeCandyRackAddToCartDiscount(
  discount: string,
  variantId?: number,
) {
  const currentDiscounts =
    storage.getItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY) || '';
  logInfo(`Current discounts: ${currentDiscounts}`, REGISTERED_APPS.CANDYRACK);

  const currentDiscountedProducts = getDiscountedProductWithDiscount();

  const updatedProducts = currentDiscountedProducts.filter(
    (product: any) => product.variantId !== variantId,
  );

  const isDiscountNotUsedByOtherProducts = !updatedProducts.find(
    (product: any) => product.code === discount,
  );
  if (isDiscountNotUsedByOtherProducts) {
    const updatedDiscounts = currentDiscounts
      .split(',')
      .filter((singleDiscount) => singleDiscount !== discount)
      .join(',');
    storage.setItem(
      CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY,
      updatedDiscounts,
    );
    logInfo(
      `Discount ${discount} not used by other products, removed. Updated discounts: ${updatedDiscounts}`,
      REGISTERED_APPS.CANDYRACK,
    );
  }
  storage.setItem(
    CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY,
    JSON.stringify(updatedProducts),
  );
}

export function updateDiscounts(runningAppName: REGISTERED_APPS) {
  applyDiscount(runningAppName);
  updateCheckoutLinks(runningAppName);
}

export function getDiscountCodesString(
  runningAppName: REGISTERED_APPS,
): string {
  let code: string | null = null;
  if (REGISTERED_APPS.CANDYRACK === runningAppName) {
    code = storage.getItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY);
  }
  return code || '';
}

export function isMultipleDiscountsExist(
  runningAppName: REGISTERED_APPS,
): boolean {
  const discounts = getDiscountCodesString(runningAppName).trim();
  return discounts.split(',').length > 1;
}

/*
 * Returns the discount codes as an escaped string, to avoid broken
 * URLs by the percentage sign
 * Different discounts are escaped by comma.
 * Hint: note that encodeURIComponent(null) will return 'null', and similar
 * behaviour for undefined.
 */
export function getDiscountCodesStringSeparatedByCommaAndEscaped(
  runningAppName: REGISTERED_APPS,
): string {
  const discountCodesString = getDiscountCodesString(runningAppName)?.trim();
  if (!discountCodesString) {
    return '';
  }
  const discountCodesArray = discountCodesString.split(',');
  return (
    discountCodesArray
      // We are choosing encodeURIComponent over encodeURI, as we want to encode only part of the URL.
      .map((discount) => encodeURIComponent(discount))
      .join(',')
  );
}

function applyDiscount(runningAppName: REGISTERED_APPS) {
  const code = getDiscountCodesString(runningAppName);
  const discountsArray = code.split(',');

  if (REGISTERED_APPS.CANDYRACK === runningAppName) {
    attachDiscountInfoForCandyrack(code);
  }

  // Note: its unclear if window.chCouponCode is needed. Potentially we can remove this
  window.chCouponCode = code;
  const forms = document.querySelectorAll<HTMLFormElement>(FORMS);

  // Hidden input is only useful for one discount
  if (discountsArray.length > 1 || discountsArray[0] === '') {
    Array.from(forms).forEach((form) => {
      const discountInput =
        form.querySelector<HTMLInputElement>('[name=discount]');
      if (discountInput) {
        logInfo(`Removing discount input ${discountInput}`, runningAppName);
        discountInput.remove();
      }
    });
    return;
  }
  Array.from(forms).forEach((form) => {
    const discountInput =
      form.querySelector<HTMLInputElement>('[name=discount]');
    if (!discountInput) {
      logInfo(`Inserting discount input ${discountInput}`, runningAppName);
      form.insertAdjacentHTML(
        'beforeend',
        `<input type="hidden" name="discount" value="${code}" />`,
      );
    } else if (discountInput.value.trim() === '' && code !== ' ') {
      // here we know we have only one discount, so we can ignore delimiters
      // veryfing if code exists before encoding it, to be safe. It seems
      // though impossible to come here with code being undefined/null
      const discountInputValue = code ? encodeURIComponent(code) : code;
      logInfo(`New discount input value ${discountInputValue}`, runningAppName);
      discountInput.value = discountInputValue;
    }
  });
}

function updateCheckoutLinks(runningAppName: REGISTERED_APPS) {
  const code = getDiscountCodesStringSeparatedByCommaAndEscaped(runningAppName);
  const weglotLanguage = window.Weglot && window.Weglot.getCurrentLang();
  const linkWithCode = `/checkout?discount=${code}${
    weglotLanguage ? `&locale=${weglotLanguage}` : ''
  }`;

  function checkoutClickHandler(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    logInfo(`Checkout click handler, redirecting to ${linkWithCode}`);
    window.location.href = linkWithCode;
  }

  const nodes = document.querySelectorAll(
    '[href^="/checkout"],[name=return_to][value^="/checkout"],button[onclick],[data-component-checkout-button]',
  );
  Array.from(nodes).forEach((node) => {
    if (node instanceof HTMLAnchorElement) {
      node.href = linkWithCode;
    }
    if (node instanceof HTMLInputElement) {
      node.value = linkWithCode;
    }
    if (
      node instanceof HTMLButtonElement &&
      node.attributes
        .getNamedItem('onclick')
        ?.value?.match(/location.*checkout/)
    ) {
      node.onclick = checkoutClickHandler;
    }
    // FBBCApproved.com and TruLean.com
    if (
      node instanceof HTMLButtonElement &&
      node.attributes.getNamedItem('data-component-checkout-button')
    ) {
      node.onclick = checkoutClickHandler;
    }
  });
}

async function attachDiscountInfoForCandyrack(codes: string) {
  logInfo('Showing discount info in cart', REGISTERED_APPS.CANDYRACK);

  if (!codes.trim()) {
    logInfo('No discount, returning.', REGISTERED_APPS.CANDYRACK);
    return;
  }

  if (!document.querySelector("a[href*='?variant=']")) {
    logInfo('No variant, returning.', REGISTERED_APPS.CANDYRACK);
    return;
  }

  const cart = await getCart();
  if (cart.items.length === 0) {
    return;
  }
  const productIds = cart.items.map((item: any) => item.product_id);
  const variantIds = cart.items.map((item: any) => item.variant_id);
  const codesArray = codes.split(',');
  const variantsEligibleForDiscount = await productsCoveredByDiscount(
    codesArray,
    productIds,
    variantIds,
  );
  showInfoInCart(variantsEligibleForDiscount);
}

function watchForDrawerCart(runningAppName: REGISTERED_APPS) {
  const throttledApplyDiscount = throttle(
    () => applyDiscount(runningAppName),
    1000,
    true,
  );
  const throttledUpdateCheckoutLinks = throttle(
    () => updateCheckoutLinks(runningAppName),
    1000,
    true,
  );
  const throttledCleanupAssociatedProductData = throttle(
    () => loadCartAndCleanupAssociatedProductData(runningAppName),
    3000,
  );
  const callback = function (mutationsList: any) {
    throttledUpdateCheckoutLinks();
    throttledCleanupAssociatedProductData();

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (var node of mutation.addedNodes) {
          if (
            (node.action &&
              (node.action.indexOf('/cart') > -1 ||
                node.action.indexOf('/checkout') > -1 ||
                node.action.indexOf('/a/checkout') > -1)) ||
            (node.querySelector && node.querySelector("a[href*='?variant=']")) // shopblends.com
          ) {
            throttledApplyDiscount();
          }
        }
      }
    }
  };

  try {
    const observer = new MutationObserver(callback);
    // When store has some constantly moving part (e.g. timer), the callback is triggered all the time - can be adjusted by targeting a different element
    const targetNode =
      window.CANDYRACK_GET_DRAWER_CART_WATCHER_CUSTOM_NODE || document.body;
    observer.observe(targetNode, { childList: true, subtree: true });
  } catch (error) {
    logError('Failed to setup drawer cart observer', runningAppName, error);
  }
}

export function clearDiscount() {
  logInfo(`Clear discount codes information`, REGISTERED_APPS.CANDYRACK);
  storage.removeItem(CANDYRACK_DISCOUNT_CODES_LOCAL_STORAGE_KEY);
  storage.removeItem(CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY);
}

export function getDiscountedProductWithDiscount() {
  const data = storage.getItem(CANDYRACK_DISCOUNTED_PRODUCTS_LOCAL_STORAGE_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch {
      return [];
    }
  }
  return [];
}

export function handleDiscounts(runningAppName: REGISTERED_APPS) {
  updateDiscounts(runningAppName);
  watchForDrawerCart(runningAppName);
}

export function calculateDiscountedPrice(
  variantPrice: number,
  amount: number,
  amount_type?: string,
  apply_discount?: boolean,
  app?: REGISTERED_APPS,
) {
  if (apply_discount) {
    const discount =
      amount_type === 'fixed_amount' ? amount : (variantPrice * amount) / 100;

    const discountedPrice = variantPrice - discount;
    logInfo(
      `Discount of ${discount} applied, returning discountedPrice : ${discountedPrice}`,
      app,
    );
    return discountedPrice;
  } else {
    logInfo(
      `Discount is not set to be applied, returning original variantPrice : ${variantPrice}`,
      app,
    );
    return variantPrice;
  }
}

export async function cleanupInvalidDiscountData(
  cart: any,
  runningAppName: REGISTERED_APPS,
) {
  const discountedProducts = getDiscountedProductWithDiscount();

  discountedProducts.forEach((discountedProduct: any) => {
    const discountedProductInCart = cart.items.find(
      (item: any) =>
        Number(item.variant_id) === Number(discountedProduct.variantId),
    );
    if (!discountedProductInCart) {
      removeCandyRackAddToCartDiscount(
        discountedProduct.code,
        Number(discountedProduct.variantId),
      );
    }
    const relatedProductInCart = cart.items.find(
      (item: any) =>
        Number(item.product_id) ===
          Number(discountedProduct.relatedProductId) ||
        !discountedProduct.hasParent,
    );
    if (!relatedProductInCart) {
      removeCandyRackAddToCartDiscount(
        discountedProduct.code,
        Number(discountedProduct.variantId),
      );
      logInfo(
        `Related product ${discountedProduct.variantId} not found. Removing discount info...`,
        runningAppName,
      );
      applyDiscount(runningAppName);
    }
  });
}
